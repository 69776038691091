import React from 'react'
import { Subscribe } from 'unstated'
import { pluck, uniq } from 'ramda'
import { sortBy } from 'lodash'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

import DateIcon from '@material-ui/icons/DateRange'

import SavingsContainer from '../containers/SavingsContainer'
import FiltersContainer from '../containers/FiltersContainer'

interface State {
  showYearDialog: boolean
}

export default class AppBarDatePicker extends React.Component<{}, State> {
  state = { showYearDialog: false }

  render() {
    return (
      <Subscribe to={[SavingsContainer, FiltersContainer]}>
        {(savingsData: any, filters: any) => (
          <>
            {savingsData.state.periods.length > 0 && (
              <IconButton
                color="inherit"
                onClick={this.handleChooseYear}
                data-testid="btn-pick-period"
              >
                <DateIcon />
              </IconButton>
            )}

            <Dialog
              open={this.state.showYearDialog}
              onClose={this.handleYearDialogClose}
            >
              <DialogTitle>Choose year to view</DialogTitle>
              <DialogContent>
                <div>
                  <RadioGroup
                    value={String(filters.state.year || 'all')}
                    onChange={this.handleOnYearChange(filters.setYear)}
                  >
                    <FormControlLabel
                      key="all"
                      value="all"
                      label="All Years"
                      control={
                        <Radio color="primary" data-testid="option-year-all" />
                      }
                    />
                    {sortBy(uniq(pluck('year', savingsData.state.periods)), year => -year).map(
                      year => (
                        <FormControlLabel
                          key={String(year)}
                          value={String(year)}
                          label={year}
                          control={
                            <Radio
                              color="primary"
                              data-testid={`option-year-${year}`}
                            />
                          }
                        />
                      )
                    )}
                  </RadioGroup>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={this.handleYearDialogClose}
                  data-testid="btn-view"
                >
                  View
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Subscribe>
    )
  }

  handleChooseYear = () => {
    this.setState({ showYearDialog: true })
  }

  handleYearDialogClose = () => {
    this.setState({ showYearDialog: false })
  }

  handleOnYearChange = setYear => e => {
    const { value } = e.target
    const year = value === 'all' ? null : parseInt(value, 10)

    setYear(year)
  }
}
