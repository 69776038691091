import { Container } from 'unstated'

import withLocalStorage from './withLocalStorage'

export interface FiltersState {
  year: number
}

class FiltersContainer extends Container<FiltersState> {
  state = { year: new Date().getFullYear() }

  setYear = (year: number) => {
    this.setState({ year })
  }
}

export default withLocalStorage(FiltersContainer, {
  name: 'filters',
  version: 1,
})
